// Breakpoint viewport sizes and media queries.
//
// Breakpoints are defined as a map of (name: minimum width), order from small to large:
//
//    (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px)
//
// The map defined in the `$grid-breakpoints` global variable is used as the `$breakpoints` argument by default.

// Name of the next breakpoint, or null for the last breakpoint.
//
//    >> breakpoint-next(sm)
//    md
//    >> breakpoint-next(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    md
//    >> breakpoint-next(sm, $breakpoint-names: (xs sm md lg xl))
//    md
@function breakpoint-next($name, $breakpoints: $grid-breakpoints, $breakpoint-names: map-keys($breakpoints)) {
  $n: index($breakpoint-names, $name);
  @return if($n < length($breakpoint-names), nth($breakpoint-names, $n + 1), null);
}

// Minimum breakpoint width. Null for the smallest (first) breakpoint.
//
//    >> breakpoint-min(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    576px
@function breakpoint-min($name, $breakpoints: $grid-breakpoints) {
  $min: map-get($breakpoints, $name);
  @return if($min != 0, $min, null);
}

// Maximum breakpoint width. Null for the largest (last) breakpoint.
// The maximum value is calculated as the minimum of the next one less 0.02px
// to work around the limitations of `min-` and `max-` prefixes and viewports with fractional widths.
// See https://www.w3.org/TR/mediaqueries-4/#mq-min-max
// Uses 0.02px rather than 0.01px to work around a current rounding bug in Safari.
// See https://bugs.webkit.org/show_bug.cgi?id=178261
//
//    >> breakpoint-max(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    767.98px
@function breakpoint-max($name, $breakpoints: $grid-breakpoints) {
  $next: breakpoint-next($name, $breakpoints);
  @return if($next, breakpoint-min($next, $breakpoints) - .02px, null);
}

// Returns a blank string if smallest breakpoint, otherwise returns the name with a dash in front.
// Useful for making responsive utilities.
//
//    >> breakpoint-infix(xs, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    ""  (Returns a blank string)
//    >> breakpoint-infix(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    "-sm"
@function breakpoint-infix($name, $breakpoints: $grid-breakpoints) {
  @return if(breakpoint-min($name, $breakpoints) == null, "", "-#{$name}");
}

// Media of at least the minimum breakpoint width. No query for the smallest breakpoint.
// Makes the @content apply to the given breakpoint and wider.
@mixin media-breakpoint-up($name, $breakpoints: $grid-breakpoints) {
  $min: breakpoint-min($name, $breakpoints);
  @if $min {
    @media (min-width: $min) {
      @content;
    }
  } @else {
    @content;
  }
}

// Media of at most the maximum breakpoint width. No query for the largest breakpoint.
// Makes the @content apply to the given breakpoint and narrower.
@mixin media-breakpoint-down($name, $breakpoints: $grid-breakpoints) {
  $max: breakpoint-max($name, $breakpoints);
  @if $max {
    @media (max-width: $max) {
      @content;
    }
  } @else {
    @content;
  }
}

// Media that spans multiple breakpoint widths.
// Makes the @content apply between the min and max breakpoints
@mixin media-breakpoint-between($lower, $upper, $breakpoints: $grid-breakpoints) {
  $min: breakpoint-min($lower, $breakpoints);
  $max: breakpoint-max($upper, $breakpoints);

  @if $min != null and $max != null {
    @media (min-width: $min) and (max-width: $max) {
      @content;
    }
  } @else if $max == null {
    @include media-breakpoint-up($lower, $breakpoints) {
      @content;
    }
  } @else if $min == null {
    @include media-breakpoint-down($upper, $breakpoints) {
      @content;
    }
  }
}

// Media between the breakpoint's minimum and maximum widths.
// No minimum for the smallest breakpoint, and no maximum for the largest one.
// Makes the @content apply only to the given breakpoint, not viewports any wider or narrower.
@mixin media-breakpoint-only($name, $breakpoints: $grid-breakpoints) {
  $min: breakpoint-min($name, $breakpoints);
  $max: breakpoint-max($name, $breakpoints);

  @if $min != null and $max != null {
    @media (min-width: $min) and (max-width: $max) {
      @content;
    }
  } @else if $max == null {
    @include media-breakpoint-up($name, $breakpoints) {
      @content;
    }
  } @else if $min == null {
    @include media-breakpoint-down($name, $breakpoints) {
      @content;
    }
  }
}

// Framework grid generation
//
// Used only by Bootstrap to generate the correct number of grid classes given
// any value of `$grid-columns`.

@mixin make-grid-columns($columns: $grid-columns, $gutter: $grid-gutter-width, $breakpoints: $grid-breakpoints) {
  // Common properties for all breakpoints
  %grid-column {
    position: relative;
    width: 100%;
    min-height: 1px; // Prevent columns from collapsing when empty
    padding-right: ($gutter / 2);
    padding-left: ($gutter / 2);
  }

  @each $breakpoint in map-keys($breakpoints) {
    $infix: breakpoint-infix($breakpoint, $breakpoints);

    // Allow columns to stretch full width below their breakpoints
    @for $i from 1 through $columns {
      .col#{$infix}-#{$i} {
        @extend %grid-column;
      }
    }
    .col#{$infix},
    .col#{$infix}-auto {
      @extend %grid-column;
    }

    @include media-breakpoint-up($breakpoint, $breakpoints) {
      // Provide basic `.col-{bp}` classes for equal-width flexbox columns
      .col#{$infix} {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
      }
      .col#{$infix}-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: none; // Reset earlier grid tiers
      }

      @for $i from 1 through $columns {
        .col#{$infix}-#{$i} {
          @include make-col($i, $columns);
        }
      }

      .order#{$infix}-first { order: -1; }

      .order#{$infix}-last { order: $columns + 1; }

      @for $i from 0 through $columns {
        .order#{$infix}-#{$i} { order: $i; }
      }

      // `$columns - 1` because offsetting by the width of an entire row isn't possible
      @for $i from 0 through ($columns - 1) {
        @if not ($infix == "" and $i == 0) { // Avoid emitting useless .offset-0
          .offset#{$infix}-#{$i} {
            @include make-col-offset($i, $columns);
          }
        }
      }
    }
  }
}

/// Grid system
//
// Generate semantic grid columns with these mixins.

@mixin make-container() {
  width: 100%;
  padding-right: ($grid-gutter-width / 2);
  padding-left: ($grid-gutter-width / 2);
  margin-right: auto;
  margin-left: auto;
}


// For each breakpoint, define the maximum width of the container in a media query
@mixin make-container-max-widths($max-widths: $container-max-widths, $breakpoints: $grid-breakpoints) {
  @each $breakpoint, $container-max-width in $max-widths {
    @include media-breakpoint-up($breakpoint, $breakpoints) {
      max-width: $container-max-width;
    }
  }
}

@mixin make-row() {
  display: flex;
  flex-wrap: wrap;
  margin-right: ($grid-gutter-width / -2);
  margin-left: ($grid-gutter-width / -2);
}

@mixin make-col-ready() {
  position: relative;
  // Prevent columns from becoming too narrow when at smaller grid tiers by
  // always setting `width: 100%;`. This works because we use `flex` values
  // later on to override this initial width.
  width: 100%;
  min-height: 1px; // Prevent collapsing
  padding-right: ($grid-gutter-width / 2);
  padding-left: ($grid-gutter-width / 2);
}

@mixin make-col($size, $columns: $grid-columns) {
  flex: 0 0 percentage($size / $columns);
  // Add a `max-width` to ensure content within each column does not blow out
  // the width of the column. Applies to IE10+ and Firefox. Chrome and Safari
  // do not appear to require this.
  max-width: percentage($size / $columns);
}

@mixin make-col-offset($size, $columns: $grid-columns) {
  $num: $size / $columns;
  margin-left: if($num == 0, 0, percentage($num));
}


// Bootstrap functions
//
// Utility mixins and functions for evaluating source code across our variables, maps, and mixins.

// Ascending
// Used to evaluate Sass maps like our grid breakpoints.
@mixin _assert-ascending($map, $map-name) {
  $prev-key: null;
  $prev-num: null;
  @each $key, $num in $map {
    @if $prev-num == null {
      // Do nothing
    } @else if not comparable($prev-num, $num) {
      @warn "Potentially invalid value for #{$map-name}: This map must be in ascending order, but key '#{$key}' has value #{$num} whose unit makes it incomparable to #{$prev-num}, the value of the previous key '#{$prev-key}' !";
    } @else if $prev-num >= $num {
      @warn "Invalid value for #{$map-name}: This map must be in ascending order, but key '#{$key}' has value #{$num} which isn't greater than #{$prev-num}, the value of the previous key '#{$prev-key}' !";
    }
    $prev-key: $key;
    $prev-num: $num;
  }
}

// Starts at zero
// Another grid mixin that ensures the min-width of the lowest breakpoint starts at 0.
@mixin _assert-starts-at-zero($map) {
  $values: map-values($map);
  $first-value: nth($values, 1);
  @if $first-value != 0 {
    @warn "First breakpoint in `$grid-breakpoints` must start at 0, but starts at #{$first-value}.";
  }
}

$grid-columns:                12 !default;
$grid-gutter-width:           30px !default;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1024px,
  xl: 1240px
) !default;

@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
@include _assert-starts-at-zero($grid-breakpoints);

.row {
  @include make-row();
  &.no-gutters {
    margin-right: 0;
    margin-left: 0;

    > .col,
    > [class*="col-"] {
      padding-right: 0;
      padding-left: 0;
    }
  }
}

@include make-grid-columns();
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

// .slick-slider {
//   position: relative;
//   display: block;
//   user-select: none;
//   -webkit-touch-callout: none;
//   -khtml-user-select: none;
//   -ms-touch-action: pan-y;
//   touch-action: pan-y;
//   -webkit-tap-highlight-color: transparent;
// }
// .slick-list {
//   margin: 0 -20px;
// }
// .slick-slide {
//   margin: 0 20px;
// }


// .slick-list {
//   position: relative;
//   display: block;
//   overflow: hidden;
//   margin: 0;
//   padding: 0;
// }

// .slick-list:focus {
//   outline: none;
// }

// .slick-list.dragging {
//   cursor: pointer;
//   cursor: hand;
// }

// .slick-track {
//   position: relative;
//   top: 0;
//   left: 0;
//   display: block;
// }

// .slick-track::before,
// .slick-track::after {
//   display: table;
//   content: '';
// }

// .slick-track::after {
//   clear: both;
// }

// .slick-slider .slick-track,
// .slick-slider .slick-list {
//   transform: translate3d(0, 0, 0);
// }

// .slick-loading .slick-track {
//   visibility: hidden;
// }

// .slick-slide {
//   display: none;
//   float: left;
//   outline: none;
//   height: 100%;
//   min-height: 1px;
// }

// .slick-slide img {
//   display: block;
// }

// .slick-slide.slick-loading img {
//   display: none;
// }

// .slick-slide.dragging img {
//   pointer-events: none;
// }

// .slick-initialized .slick-slide {
//   display: block;
// }

// .slick-loading .slick-slide {
//   visibility: hidden;
// }

// .slick-arrow.slick-hidden {
//   display: none;
// }

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, figcaption, figure, footer, header, hgroup, menu, nav, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
  -webkit-tap-highlight-color: transparent;
}

::-webkit-input-placeholder {
  color: rgba(0, 0, 0 ,.3);
}

::-moz-placeholder {
  color: rgba(0, 0, 0 ,.3);
  opacity: 1;
}

input[type=tel], input[type=url], input[type=password], input[type=text], input[type=email], input[type=reset], input[type=button], input[type=submit], button, textarea {
  -webkit-appearance: none;
  -webkit-border-radius: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

img {
  vertical-align: middle;
  max-width: 100%;
  height: auto;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}


a[href], label[for], select, input[type=checkbox], input[type=radio] {
  cursor: pointer;
}

button, input[type=button], input[type=image], input[type=reset], input[type=submit] {
  padding: 0;
  overflow: visible;
  cursor: pointer;
}

button::-moz-focus-inner, input[type=button]::-moz-focus-inner, input[type=image]::-moz-focus-inner, input[type=reset]::-moz-focus-inner, input[type=submit]::-moz-focus-inner {
  border: 0;
}

.temp-hide,
.hide {
  position: absolute;
  left: -9999em;
}

.clearfix:after {
  content: '';
  display: block;
  clear: both;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
}

strong {
  font-weight: bold;
}

em {
  font-style: italic;
}

del {
  text-decoration: line-through;
}

th, td {
  vertical-align: top;
}

th {
  font-weight: normal;
  text-align: left;
}

address, cite, dfn {
  font-style: normal;
}

sub, sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
}

sup {
  top: -.5em;
}

sub {
  bottom: -.25em;
}

textarea {
  overflow: auto;
}

html {
  height: 100%;
}

.align-items-center {
  align-items: center;
}

/* Normalized Styles
------------------------------------------------------------------------------*/

body {
  font: 20px/32px 'Roboto', sans-serif;
  background: #fff;
  -webkit-text-size-adjust: none;
  position: relative;
  min-height: 100%;
  color: #000;
  display: flex;
  flex-direction: column;
}

.body {
  flex-grow: 5;
  position: relative;
  overflow: hidden;
  white-space: pre-line;
}

.body-block {
  overflow: hidden;
}

button {
  background: none;
  padding: 0;
  border: 0;
  outline: none;
}

input, textarea, select, button {
  outline: none;
  font-family: 'Roboto', sans-serif;
}

input,
button {
  transition: all .3s ease;
}

img {
  max-width: 100%;
}

a {
  text-decoration: none;
  transition: all .3s ease;
  color: inherit;
  outline: none;
}

.container {
  padding: 0 30px;
  width: 1196px;
  margin: 0 auto;
  max-width: 100%;
  position: relative;
}

.btn {
  display: inline-block;
  background: #483BF4;
  border-radius: 8px;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  padding: 15px 20px;
  text-align: center;
  font-size: 16px;
  box-shadow: 6px 12px 27px rgba(53, 52, 56, .2);
  min-width: 170px;
  line-height: 20px;
  color: #FFFFFF;

  &:hover {
    color: #483BF4;
    background: #FFFFFF;
  }
}

/* Header
------------------------------------------------------------------------------*/

.mobile-link {
  display: none;

  @media (max-width: 1024px) {
    position: absolute;
    display: inline-flex;
    align-items: center;
    top: -2px;
    height: 35px;
    right: 70px;

    ul {
      display: inline-flex;
      justify-content: flex-end;
      align-items: center;
    }

    li:nth-child(2) {
      margin-left: 10px;
      display: inline-block;
      max-width: 100%;
    }

    ul > li {
      max-width: 30%;
      img {
        box-shadow: 0 4px 25px 0 rgba(14, 36, 70, 0.12);
      }
    }
  }
}

.header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9;
  padding: 39px 0;
  .logo {
    background: url(assets/images/logo.png) no-repeat;
    width: 96px;
    height: 44px;
    background-size: contain;
    text-indent: -999em;
    margin-left: 2px;
    display: block;
    float: left;
  }

  .logo-white {
    background: url(assets/images/logo-white.png) no-repeat;
    width: 96px;
    height: 44px;
    background-size: contain;
    text-indent: -999em;
    margin-left: 2px;
    display: block;
    float: left;
  }
}

.main-nav {
  float: right;
  font-family: 'Poppins', sans-serif;
  li {
    font-weight: 500;
    line-height: 32px;
    font-size: 16px;
    padding: 2px 4px;
    float: left;
    margin-left: 47px;

    a {
      opacity: 1;
      &:hover,
      &.active {
        opacity: .4;
      }
    }

    &:nth-child(2) {
      margin-left: 20px;
    }

    &:first-child {
      transform: translateY(-14px);
      margin-left: 0;
    }

    @media screen and (max-width: 768px) {
      &:first-child {
        display: none;
      }

      display: inline-block;
    }

  }
}

.active-restaurant {
  li {
    color: #FFFFFF;
  }

  @media screen and (max-width: 767px) {
    li {
      color: #000000;
    }
  }
}

/* Content
------------------------------------------------------------------------------*/

.hero {
  background: #ebf1fd url(assets/images/bg-hero.svg) no-repeat 50% 42%;
  background-position: calc(50% - 50px) 42%;
  display: flex;
  align-items: center;
  min-height: 700px;
  padding: 150px 0 100px;
  .image {
    position: absolute;
    top: -33px;
    right: 7px;
  }
}

h1 {
  font-weight: 500;
  line-height: normal;
  font-size: 64px;
  padding-bottom: 17px;
  line-height: 1.5;
  font-family: 'Poppins', sans-serif;
}

h2 {
  font-weight: 500;
  line-height: normal;
  font-size: 40px;
  padding-bottom: 14px;
  line-height: 1.5;
  font-family: 'Poppins', sans-serif;
}

h3 {
  font-weight: 500;
  line-height: normal;
  font-size: 32px;
  padding-bottom: 10px;
  line-height: 1.5;
  font-family: 'Poppins', sans-serif;
}

h4 {
  font-weight: 500;
  line-height: normal;
  font-size: 24px;
  padding-bottom: 0;
  line-height: 1.45;
  font-family: 'Poppins', sans-serif;
}

.shops {
  font-size: 0;
  padding: 36px 0 0;
  a {
    margin-right: 17px;
    &:hover {
      opacity: .8;
    }
  }
}

.bg {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  right: 0;
  background: no-repeat 50% 50%;
  background-size: contain;
}

.features {
  padding: 76px 0 116px;
  .bg {
    top: 3%;
    left: 2.7%;
    right: 2.6%;
  }
  article {
    margin-bottom: -55px;
    &:nth-child(1) {
      .bg {
        left: 11%;
        right: 1%;
      }
    }
    &:nth-child(2) {
      margin-bottom: -40px;
      .bg {
        left: 0%;
        top: -5%;
        right: -3%;
      }
    }
    &:nth-child(3) {
      .image {
        padding-left: 30px;
      }
    }
    &:nth-child(even) {
      .image {
        margin-left: -70px;
      }
    }
  }
  .image {
    position: relative;
    padding: 24px 0 0;
    text-align: center;
    margin-left: -20px;
  }
}

.available {
  font-size: 14px;
  padding: 20px 0 0;
  a {
    margin-left: 10px;
    margin-right: -5px;
    vertical-align: top;
    position: relative;
    display: inline-block;
  }
}

.cta-row {
  background: #483BF4 url(assets/images/bg-mask-b.svg) no-repeat 85% 55%;
  background-position: calc(50% + 468px) 55;
  padding: 150px 0;
  color: #fff;
  h2 {
    font-size: 48px;
    padding-bottom: 18px;
  }
  .image {
    text-align: right;
    margin: 0 -7% -13.8% 0;
  }
  p {
    font-size: 24px;
    line-height: 30px;
  }
}

.interest-row {
  min-height: 579px;
  padding: 50px 0;
  background: #F3A89B;
  position: relative;
  display: flex;
  align-items: center;
  .gallery {
    width: 50%;
  }
  .btn {
    margin-top: 26px;
  }
}

.slick-dots {
  position: absolute;
  bottom: 0;
  z-index: 3;
  right: 0;
  margin: 48px 47px;
  li {
    float: left;
    padding-left: 13px;
  }
  button {
    width: 8px;
    height: 8px;
    background: #FFFFFF;
    cursor: pointer;
    display: block;
    text-indent: -999em;
    border-radius: 50%;
    opacity: 0.2;
    &:hover {
      opacity: .5;
    }
  }
  .slick-active button {
    opacity: 1;
  }
}

.gallery {
  position: absolute;
  top: 0;
  bottom: 0;
  background: #313138;
  left: 0;
  z-index: 2;
  right: 0;
  .slick-track,
  .slick-list {
    height: 100%;
  }
  .bg {
    background-size: cover;
  }

  .box {
    height: 579px;

    @media screen and (max-width: 767px) {
      height: 422px;
    }

    z-index: 1;
    position: relative;
    &:before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.0001) 0%, #313138 100%);
      height: 50%;
    }
  }
  .meta {
    font-size: 12px;
    line-height: normal;
  }
  .text {
    position: absolute;
    bottom: 0;
    left: 0;
    font-size: 14px;
    right: 0;
    padding: 42px 48px;
    padding-right: 100px;
    color: #fff;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
  }
}

.retina {
  display: none;
}

/* Footer
------------------------------------------------------------------------------*/

.footer {
  padding: 124px 0 0;
  font-size: 18px;
  h5 {
    font-weight: normal;
    line-height: 1.2;
    padding-bottom: 25px;
  }
  a {
    color: rgba(0, 0, 0, .4);
    &:hover {
      color: #000;
    }
  }
  li {
    padding-bottom: 19px;
  }
  .copy {
    font-size: 14px;
    font-weight: 500;
    padding: 63px 0 80px;
    p {
      display: inline-block;
      margin-right: 53px;
    }
    p:nth-child(2) + p,
    p:nth-child(3) + p {
      margin-left: -18px;
    }
  }
}

/* Media
------------------------------------------------------------------------------*/


@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .non-retina {
    display: none;
  }
  .retina {
    display: block;
  }
}

@media (max-width: 1196px) {

  .cta-row h2 {
    font-size: 40px;
  }

  .cta-row p {
    font-size: 20px;
    line-height: 1.3;
  }

  h1 {
    font-size: 50px;
  }

}

@media (max-width: 1023px) {

  body {
    font-size: 18px;
    line-height: 28px;
  }

  .features .image {
    padding-left: 10%;
    padding-right: 10%;
  }

  .features article:nth-child(even) .image {
    margin-left: 0;
  }

  h1 {
    font-size: 44px;
    line-height: 1.5;
  }

  h2 {
    font-size: 34px;
    line-height: 1.2;
  }

  h3 {
    font-size: 26px;
  }

  h4 {
    font-size: 18px;
  }

  .cta-row .image {
    margin: 0 -10% -25%;
  }

  .hero {
    min-height: 550px;
    background-size: auto 250px;
  }

  .hero .image {
    img {
      max-height: 600px;
    }
  }

}

@media (max-width: 1024px) {

  body {
    font-size: 16px;
    line-height: 24px;
  }

  .container {
    padding-left: 20px;
    padding-right: 20px;
  }

  h1 {
    font-size: 38px;
  }

  h2 {
    font-size: 24px;
  }

  h3 {
    font-size: 22px;
  }

  h4 {
    font-size: 18px;
  }

  .main-nav {
    opacity: 0;
    transition: opacity .3s ease;
    position: absolute;
    top: 70px;
    min-height: 100vh;
    left: 0;
    width: 100%;
    z-index: 9;
    background: #ebf1fd;
    pointer-events: none;
    .active-menu & {
      opacity: 1;
      pointer-events: auto;
      left: 0;
    }
    li {
      float: none;
      display: block;
      text-align: center;
      padding: 15px 0 0;
      margin: 0;
      font-size: 20px;
    }
  }

  .header {
    padding-top: 20px;
  }

  .hero {
    padding: 100px 0 10px;
    min-height: 450px;
    background-size: auto 180px;
    background-position: 50% 100px;
    .image {
      position: static;
      margin: 40px 0 0 -30px;
      text-align: center;
      img {
        width: 200px;
      }
    }
  }

  .shops {
    display: flex;
    margin-right: -20px;
    padding-top: 20px;
    a {
      margin-right: 20px;
    }
  }

  .features {
    padding: 50px 0 0;
    margin-bottom: -25px;
    article {
      margin-bottom: 20px;
    }
    .image {
      margin-left: 0;
      margin-bottom: 50px;
      div.bg {
        margin: auto;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: 70%;
        width: 100%;
      }
      img {
        max-height: 350px;
      }
    }
  }

  .features article:nth-child(2) {
    margin-bottom: 20px;
  }

  .cta-row {
    padding: 50px 0;
    min-height: 0;
    .image {
      padding-top: 35px;
      margin: 0 0 -70px;
      img {
        max-height: 250px;
      }
    }
  }

  .interest-row {
    display: block;
    padding: 0 0 50px;
    min-height: 0;

    .gallery {
      width: auto;
      height: 100%;
      position: relative;
      margin-bottom: 50px;
      height: 100vw;
      max-height: 400px;
    }
  }

  .slick-dots {
    margin: 20px 18px;
  }

  .gallery .text {
    padding: 12px 70px 12px 20px;
    font-size: 12px;
  }

  .cta-row h2 {
    font-size: 28px;
  }

  .cta-row p {
    font-size: inherit;
    line-height: inherit;
  }

  .footer {
    padding: 40px 0;
    font-size: 16px;
    line-height: normal;
    img {
      margin-bottom: 40px;
    }
    .copy {
      padding: 0;

      p {
        margin: 15px 0 0 !important;
        display: block;
      }
    }
    ul {
      padding-bottom: 20px;
    }
  }

  .menu-trigger {
    position: absolute;
    top: -2px;
    bottom: 0;
    width: 25px;
    height: 35px;
    padding-top: 11px;
    right: 20px;
    cursor: pointer;
    span {
      display: block;
      margin-bottom: 4px;
      height: 2px;
      transition: all .3s ease;
      background: #000;

      .active-menu &:nth-child(2) {
        opacity: 0;
      }
      .active-menu &:nth-child(1) {
        transform: rotate(45deg);
        margin: 7px 0 -8px;
      }
      .active-menu &:nth-child(3) {
        transform: rotate(-45deg);
      }
    }
  }

  .active-restaurant {
    span {
      background: #FFF;
    }
}

}

@media (min-width: 576px) and (max-width: 767px) {

  .hero {
    .image {
      position: absolute;
      top: 50%;
      margin-top: -120px;
      right: 5%;
    }
  }
}

.text-content {
  margin-top: 122px;
  padding: 39px 0;

  &.in-app {
    margin-top: 0 !important;
  }

  ol {
    counter-reset: item;
    margin-left: 30px;
  }

  li {
    display: block;

    &::before {
      content: counters(item, ".") ". ";
      counter-increment: item;
    }
  }

  a {
    text-decoration: underline;
  }

  @media (max-width: 767px) {
    margin-top: 84px;
    padding: 20px 0;
  }
}

.contacts {
  background: #ebf1fd;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 700px;
  padding: 150px 0 100px;

  @media (max-width: 767px) {
    padding: 150px 20px;
    display: block;
    min-height: auto;
  }
}

.contacts-headings {
  width: 400px;
  text-align: center;
  margin-bottom: 50px;

  @media (max-width: 767px) {
    width: auto;
    text-align: left;
  }
}

.contacts-content {
  display: flex;
  align-items: self-start;

  @media (max-width: 767px) {
    flex-direction: column;
  }
}

.contacts-content-col-separator {
  width: 1px;
  height: 110px;
  background: rgba(#979797, 0.2);
  margin: 0 80px;
  flex-shrink: 0;

  @media (max-width: 767px) {
    display: none;
  }
}

.contacts-content-col {
  display: flex;
  padding: 15px;
  flex-shrink: 0;
  align-items: center;

  @media (max-width: 767px) {
    padding: 0;
  }

  &:first-child {
    margin-bottom: 20px;
  }

  .icon {
    flex-shrink: 0;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    border: 2px solid rgba(0, 0, 0, 0.1);
    margin-right: 30px;
  }
  .meta {
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    line-height: 24px;

    .meta-title {
      display: block;
    }

    .meta-content {
      display: block;
      font-weight: 500;

      .link {
        color: #483BF4;
      }
    }
  }
}

.img-width {
  width: 100%;

  .image {
    width: 100%;
    margin-left: auto !important;
  }
}
